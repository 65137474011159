import { getTrackingEventsFromElement } from "./attributes.js";
import { ELEMENT_SELECTOR, getTrackableElements } from "./elements.js";
import { synteticEvents } from "./event.js";
import { trackingEventListener } from "./listener.js";
import { emitPageImpressionEvent as dispatchPageImpression } from "./pageImpression.js";
import { registerVisibilityTracking, unregisterVisibilityTracking } from "./visible.js";

export const tracked = new WeakMap<
  HTMLElement,
  [listener: typeof trackingEventListener, string[]]
>();

/**
 *
 *
 *
 *
 */
export function registerTrackingHandler(elementToTrack: HTMLElement): void {
  if (tracked.has(elementToTrack)) {
    return;
  }

  const eventsToTrack = getTrackingEventsFromElement(elementToTrack);

  /*                                                                     */
  const listener = trackingEventListener.bind(elementToTrack);

  eventsToTrack.forEach((eventName) => {
    elementToTrack.addEventListener(eventName, listener, { passive: true });
  });

  /*                                                                 */
  tracked.set(elementToTrack, [listener, eventsToTrack]);

  /*                                                     */
  if (eventsToTrack.includes(synteticEvents[0])) {
    registerVisibilityTracking(elementToTrack);
  }

  if (eventsToTrack.includes(synteticEvents[1])) {
    dispatchPageImpression(elementToTrack);
  }
}

/**
 *
 *
 *
 *
 */
export function unregisterTrackingHandler(elementToTrack: HTMLElement): void {
  const cacheData = tracked.get(elementToTrack);
  if (!cacheData) {
    return;
  }

  /*                                                                          */
  cacheData[1].forEach((eventName) => {
    elementToTrack.removeEventListener(eventName, cacheData[0]);
  });

  /*                                                               */
  unregisterVisibilityTracking(elementToTrack);

  /*                                   */
  tracked.delete(elementToTrack);
}

export const theBody = window.o_util.dom.getBodyElement();

/*                                                           */
/*                                                             */
theBody.querySelectorAll<HTMLElement>(ELEMENT_SELECTOR).forEach(registerTrackingHandler);

/*                                                                    */
export const observer = new MutationObserver((mutations) => {
  mutations
    .filter((m) => m.type === "childList")
    .forEach((mutation) => {
      const trackableElements = getTrackableElements(mutation.addedNodes);
      trackableElements.forEach(registerTrackingHandler);

      const removedElements = getTrackableElements(mutation.removedNodes);
      removedElements.forEach(unregisterTrackingHandler);
    });
});

/*                                     */
observer.observe(theBody, { childList: true, subtree: true });
