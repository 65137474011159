/*                            */
/*                             */
/*                                     */

const ignoreKeys = [/^_/, /^\$/];

export function flatObject<T extends object>(
  obj: T,
  acc: Record<string, string | number> = {},
  parent: string | undefined = undefined,
  tracked = new Set(),
  level = 0,
): Record<string, string | number> {
  /*                                */
  tracked.add(obj);

  /*                                                     */
  /*                           */
  for (const key in obj) {
    if (ignoreKeys.some((re) => re.test(key))) {
      /*                               */
      continue;
    }

    const value = obj[key];

    /*                  */
    if (typeof value === "function" || typeof value === "symbol") {
      continue;
    }

    /*                                                  */
    const flatKey = parent ? `${parent}.${key}` : key;

    /*                                         */
    acc[flatKey] = value as string | number;

    /*                              */
    if (typeof value === "object" && value !== null && !tracked.has(value) && level < 5) {
      flatObject(value as never, acc, flatKey, tracked, level + 1);
    }
  }

  return acc;
}

/**
 *
 *
 */
export function isPlaceholderString(value: unknown): value is string {
  return typeof value === "string" && /^{{.+}}$/.test(value);
}

/**
 *
 *
 *
 *
 *
 */
export function getPlaceholderValue(
  value: string,
  data: Record<string, RecursiveObjectOrValue>,
): RecursiveObjectOrValue {
  const placeholder = value.slice(2, -2);
  return data[placeholder] ?? value;
}

/**
 *
 */
export type RecursiveObjectOrValue =
  | string
  | number
  | undefined
  | null
  | { [key: string | number]: RecursiveObjectOrValue }
  | RecursiveObjectOrValue[];

/**
 *
 *
 *
 *
 */
export function replacePlaceholders(
  objOrPlaceholder: RecursiveObjectOrValue,
  data: Record<string, RecursiveObjectOrValue>,
): RecursiveObjectOrValue {
  if (isPlaceholderString(objOrPlaceholder)) {
    return getPlaceholderValue(objOrPlaceholder, data);
  }

  if (objOrPlaceholder && typeof objOrPlaceholder === "object") {
    /*                                                     */
    /*                           */

    for (const key in objOrPlaceholder) {
      const value = objOrPlaceholder[key];
      objOrPlaceholder[key] = replacePlaceholders(value, data);
    }
  }

  /*              */
  return objOrPlaceholder;
}
