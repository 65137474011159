import { apm, Transaction, type AgentConfigOptions } from "@elastic/apm-rum";
import { o_util } from "@gr-common/async/namespaces";

/**
 *
 */
const sampligRates = { live: 0.02, develop: 1 };
const noop = (): undefined => undefined;
const withTransaction =
  <R, A extends unknown[]>(
    withCb: (transaction: Transaction, ...args: NoInfer<A>) => R,
  ): ((...args: A) => R) =>
  (...a) => {
    const transaction = apm.getCurrentTransaction();
    return transaction ? withCb(transaction, ...a) : (undefined as R);
  };

/*                                     */
/*                                            */
/*                                                                                 */
/*                                     */
/**/

function isValidLocation(): boolean {
  const { hostname } = window.location;
  /*                           */
  return hostname.endsWith("otto.de");
}

function isValidSample(): boolean {
  const rateValue = Math.random();
  const sampligRate = sampligRates[import.meta.env.VITE_OTTO_INSTALLATION_ENV];
  return rateValue < sampligRate;
}

if (o_util.toggle.get("rum_apm_enabled", false)) {
  /*                                            */
  const globalConfig: AgentConfigOptions = window.__APM__ ?? {};
  const isActive = isValidLocation() && isValidSample(); /*                     */

  apm.init({
    serviceName: import.meta.env.VITE_OTTO_INSTALLATION_NAME,
    serverUrl: import.meta.env.VITE_ELASTIC_APM_SERVER_URL,
    environment: import.meta.env.VITE_OTTO_INSTALLATION_ENV,
    pageLoadTraceId: globalConfig.pageLoadTraceId,
    pageLoadSpanId: globalConfig.pageLoadSpanId,
    pageLoadSampled: globalConfig.pageLoadSampled,
    active: isActive,
  });
  apm.addLabels({
    pagecluster: o_util.misc.getPagecluster() ?? "unknown",
    navigatorua: window.navigator.userAgent,
  });

  otto.apm.assignNamespace({
    agent: () => apm,
    startSpan: apm.startSpan,
    captureError: apm.captureError,
    mark: withTransaction((t, key) => t.mark(key)),
    addLabels: withTransaction((t, labels) => t.addLabels(labels)),
    getCurrentTransaction: apm.getCurrentTransaction,
  });
} else {
  otto.apm.assignNamespace(
    {
      agent: () => null,
      startSpan: noop,
      captureError: noop,
      mark: noop,
      addLabels: noop,
      getCurrentTransaction: noop,
    },
    { lock: !import.meta.env.TEST },
  );
}
