import { dispatchTrackingEvent } from "./event";

/**
 *
 *
 *
 */
export function emitPageImpressionEvent(elementToTrack: HTMLElement): void {
  dispatchTrackingEvent(elementToTrack, "tr-page-impression", {
    "tr-v1": { method: "submitMerge" },
  });
}
